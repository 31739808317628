import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import priceShape from '@contactcentre-web/utils/shape/price';

import RailcardLabel from '../RailcardLabel';

import styles from './styles';

const PriceRow = ({
  price,
  direction,
  message,
  inventoryPrice,
  discounts = [],
  styleSheet = {},
  testId,
}) => (
  <tr data-test={testId}>
    <td className={css(styles.itemCell, styleSheet.itemCell && styleSheet.itemCell)}>
      <div className={css(styles.container)}>
        <div className={css(styles.message)}>
          <FormattedMessage {...message} />
        </div>
        {direction && <span className={css(styles.direction)}>{direction}</span>}
        {discounts?.map(({ name }, index) => (
          <RailcardLabel key={index} name={name} />
        ))}
      </div>
    </td>
    <td className={css(styles.productPriceCell)}>
      {inventoryPrice && inventoryPrice.amount && <FormattedCurrency {...inventoryPrice} />}
    </td>
    <td
      aria-label="Price"
      title="Price"
      className={css(styles.pricePaidCell, styleSheet.pricePaidCell && styleSheet.pricePaidCell)}
    >
      <FormattedCurrency amount={price.amount} currencyCode={price.currencyCode} />
    </td>
  </tr>
);

PriceRow.propTypes = {
  price: priceShape,
  message: PropTypes.object.isRequired,
  direction: PropTypes.string,
  discounts: PropTypes.arrayOf(PropTypes.object),
  styleSheet: PropTypes.object,
  inventoryPrice: priceShape,
  testId: PropTypes.string,
};

export default PriceRow;
