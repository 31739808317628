import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconPaymentCard } from '@trainline/depot-web';
import { connect } from 'react-redux';

import { selectors } from '../../module';

import OrderPayment from './OrderPayment';
import PaymentDetails from './PaymentDetails';
import messages from './messages';
import styles from './styles';

const TransactionSummary = ({ hasAmendedSeatReservation, transactionPaymentSummary }) => {
  const {
    orderTotal,
    orderTotalBeforeDiscountsAndFees,
    products,
    fees,
    discounts,
    paymentStatus,
    paymentDate,
    paymentErrors,
    paymentTypeDetails,
    paymentServiceProvider,
  } = transactionPaymentSummary;
  return (
    <section className={css(styles.container)}>
      <div className={css(styles.transactionSummaryContainer)}>
        <div className={css(styles.paymentDetailsInfo)}>
          <h3 className={css(styles.paymentSummaryTitle)}>
            <IconPaymentCard className={css(styles.iconTicket)} />
            <FormattedMessage {...messages.paymentSummary} />
          </h3>
          <PaymentDetails
            paymentTypeDetails={paymentTypeDetails}
            paymentServiceProvider={paymentServiceProvider}
          />
        </div>

        <div className={css(styles.paymentBlock, styles.rightAlign)}>
          <OrderPayment
            orderTotal={orderTotal}
            orderTotalBeforeDiscountsAndFees={orderTotalBeforeDiscountsAndFees}
            products={products}
            fees={fees}
            discounts={discounts}
            paymentStatus={paymentStatus}
            paymentDate={paymentDate}
            paymentErrors={paymentErrors}
            hasAmendedSeatReservation={hasAmendedSeatReservation}
          />
        </div>
      </div>
    </section>
  );
};

TransactionSummary.propTypes = {
  hasAmendedSeatReservation: PropTypes.bool,
  transactionPaymentSummary: PropTypes.object,
};

const mapStateToProps = (state) => ({
  transactionPaymentSummary: selectors.transactionPaymentSummary(state),
  hasAmendedSeatReservation: selectors.hasAmendedSeatReservation(state),
});

export default connect(mapStateToProps, {})(TransactionSummary);
