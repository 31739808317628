import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tag, MessageMood } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';

import type State from '@contactcentre-web/redux-common/types/State';
import { selectors } from '@contactcentre-web/customer/module';

import messages from '../StatusInfo/messages';

import styles from './styles';

interface Props {
  isGuest: boolean;
}

export const StatusInfo = ({ isGuest }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Tag
      label={formatMessage(isGuest ? messages.guest : messages.registered)}
      typeStyle="small"
      mood={MessageMood.NEUTRAL}
      className={css(styles.tag)}
    />
  );
};

const mapStateToProps = (state: State) => {
  const customer = selectors.getSelectedCustomer(state.customer);
  return {
    isGuest: customer === null || customer.isImplicitlyRegistered,
  };
};

const connected = connect(mapStateToProps)(StatusInfo);
connected.displayName = 'StatusInfo';

export default connected;
