import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

import { fadeIn } from '../../../customer-order-new/styles';

export default StyleSheet.create({
  pageContainerWrapper: {
    position: 'relative',
    backgroundColor: palettes.dp_app_backdrop_base,
    opacity: 0.5,
    animationName: [fadeIn],
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  pageHeaderWrapper: {
    padding: spacers.dp_s32,
    display: 'flex',
    gap: spacers.dp_s32,
    boxSizing: 'content-box',
  },
});
