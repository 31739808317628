import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import priceShape from '@contactcentre-web/utils/shape/price';
import camelCase from '@contactcentre-web/utils/camelCase';

import messages from './messages';
import styles from './styles';

const FeeSummary = ({ inventoryPrice, hasPrice, price, type, breakdown }) => {
  const messageId = camelCase(type);
  return (
    hasPrice && (
      <tr data-test-id="fee-price-row">
        <td className={css(styles.padHorizontal)} colSpan={4}>
          <table className={css([styles.table, styles.padHorizontal])}>
            <tbody>
              <tr>
                <td className={css([styles.itemCellHeading, styles.itemCell, styles.padLeft])}>
                  <FormattedMessage {...messages[messageId]} />
                </td>
                <td className={css(styles.priceFeeCell)}>
                  {inventoryPrice && (
                    <FormattedCurrency
                      amount={inventoryPrice.amount}
                      currencyCode={inventoryPrice.currencyCode}
                    />
                  )}
                </td>
                <td className={css(styles.pricePaidCell)}>
                  {price && <FormattedCurrency {...price} />}
                </td>
              </tr>
              {breakdown &&
                breakdown.map(
                  ({ vendor, price: breakdownPrice }, index) =>
                    !!breakdownPrice.amount && (
                      <tr key={index} data-testid="fee-breakdown-row">
                        <td className={css(styles.breakdownTypeCell)}>
                          {vendor && vendor.join(', ')}
                        </td>
                        <td aria-hidden className={css(styles.itemCell)} />
                        <td className={css([styles.priceFeeCell, styles.breakdownPriceCell])}>
                          <FormattedCurrency {...breakdownPrice} />
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </td>
      </tr>
    )
  );
};

FeeSummary.propTypes = {
  inventoryPrice: priceShape,
  price: priceShape,
  hasPrice: PropTypes.bool,
  type: PropTypes.string,
  breakdown: PropTypes.arrayOf(
    PropTypes.shape({
      price: priceShape,
      vendor: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default FeeSummary;
