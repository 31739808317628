import React from 'react';
import { Button } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';

import EditCustomerProfile from '@contactcentre-web/edit-customer-profile/EditCustomerProfile';
import { LOCALE_CODES } from '@contactcentre-web/localisation/locales';
import Menu from '@contactcentre-web/common/Menu';

import styles from './styles';

interface Props {
  canEditCustomerProfile: boolean;
  forename: string;
  surname: string;
  email: string;
  customerPreferredLanguage: LOCALE_CODES;
  customerId: string;
  showSnackbarHandler: () => void;
  fullWidth: boolean;
}
const ActionsMenu = ({
  canEditCustomerProfile,
  forename,
  surname,
  email,
  customerPreferredLanguage,
  customerId,
  showSnackbarHandler,
  fullWidth = true,
}: Props) => (
  <>
    {fullWidth ? (
      <EditCustomerProfile
        forename={forename}
        surname={surname}
        email={email}
        preferredLanguage={customerPreferredLanguage}
        customerId={customerId}
        editSucceededHandler={showSnackbarHandler}
        buttonStyleSheet={{
          button: styles.editProfileButtonFullWidth,
          active: styles.editProfileButtonActiveFullWidth,
        }}
      />
    ) : (
      <Menu
        testId="actions-menu"
        handle={<Button text="..." size="small" buttonType="tertiary" />}
        menuConfig={{
          direction: 'bottom',
          viewScroll: 'initial',
        }}
        items={[
          ...(canEditCustomerProfile
            ? [
                {
                  'data-test': 'actions-menu-edit-profile',
                  className: css(styles.editProfile),
                  handle: (
                    <EditCustomerProfile
                      forename={forename}
                      surname={surname}
                      email={email}
                      preferredLanguage={customerPreferredLanguage}
                      customerId={customerId}
                      editSucceededHandler={showSnackbarHandler}
                      buttonStyleSheet={{
                        button: styles.editProfileButton,
                        active: styles.editProfileButtonActive,
                      }}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    )}
  </>
);

export default ActionsMenu;
