import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

const Journey = () => (
  <div className={css(styles.container)}>
    <h3>Journey Details</h3>
  </div>
);

export default Journey;
