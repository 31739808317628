import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import priceShape from '@contactcentre-web/utils/shape/price';
import IconInsurance from '@contactcentre-web/common/Icons/IconInsurance';

import { insuranceMessage } from '../../utils/InsuranceMessage';

import styles from './styles';
import messages from './messages';

const Insurance = ({ insuranceType, settlementCountry, vendor }) => (
  <div className={css(styles.container)}>
    <div>
      <div className={css(styles.title)}>
        <FormattedMessage {...messages.ancillaryProducts} />
      </div>
      <div className={css(styles.values)}>
        <IconInsurance className={css(styles.insuranceIcon)} />
        <FormattedMessage {...insuranceMessage(insuranceType, settlementCountry, vendor)} />
      </div>
    </div>
  </div>
);

Insurance.propTypes = {
  price: priceShape,
  insuranceType: PropTypes.string,
  settlementCountry: PropTypes.string,
  vendor: PropTypes.string,
};

export default Insurance;
