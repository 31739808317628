import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    borderRadius: borderRadius.dp_r12,
    overflow: 'hidden',
    boxShadow: '0px 0px 4px 0px rgba(25, 35, 37, 0.12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 50%',
    height: '300px',
    marginTop: spacers.dp_s48,
  },
});
