import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export const fadeIn = {
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
};

export default StyleSheet.create({
  loaderContainer: {
    padding: spacers.dp_s48,
    height: '100%',
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageContainerWrapper: {
    position: 'relative',
    backgroundColor: palettes.dp_app_backdrop_base,
    opacity: 0.5,
    animationName: [fadeIn],
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
});
