import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import priceShape from '@contactcentre-web/utils/shape/price';
import DiscountType from '@contactcentre-web/redux-common/types/DiscountType';

import PaymentSummaryTableHead from '../PaymentSummaryTableHead';
import ProductRow from '../ProductRow';
import FeeSummary from '../FeeSummary';
import DiscountRow from '../DiscountRow';
import CreditTrainlineRow from '../CreditTrainlineRow';
import TotalAmountBeforeDiscount from '../TotalAmountBeforeDiscount';

import messages from './messages';
import styles from './styles';

const OrderPayment = ({
  orderTotal,
  orderTotalBeforeDiscountsAndFees,
  products = [],
  fees = [],
  discounts = [],
  paymentStatus,
  paymentDate,
  paymentErrors,
  hasAmendedSeatReservation,
}) => {
  const showFees = fees.length > 0 && fees.some(({ hasPrice }) => hasPrice);

  const hasPromoDiscounts =
    discounts.length > 0 && discounts.some(({ type }) => type === DiscountType.PromoDiscount);

  const hasCreditToTrainline =
    discounts.length > 0 && discounts.some(({ type }) => type === DiscountType.CreditToTrainline);

  const totalPrice =
    products.length > 0 &&
    products.reduce((a, b) => parseFloat(a) + parseFloat(b.totalPrice?.amount || 0), 0);

  return (
    <div>
      <div className={css(styles.paymentBlock)}>
        <PaymentSummaryTableHead
          paymentStatus={paymentStatus}
          errors={paymentErrors}
          paymentDate={paymentDate}
          hasAmendedSeatReservation={hasAmendedSeatReservation}
        />
        <table className={css(styles.paymentTable)}>
          <tbody>
            {products.map((product, index) => (
              <ProductRow key={index} {...product} />
            ))}
            {hasCreditToTrainline && (
              <>
                {discounts.map((discount, index) => (
                  <CreditTrainlineRow key={index} {...discount} />
                ))}
              </>
            )}
            {hasPromoDiscounts && (
              <>
                <TotalAmountBeforeDiscount totalAmount={orderTotalBeforeDiscountsAndFees} />
                <tr>
                  <td colSpan="4" className={css(styles.header)}>
                    <FormattedMessage {...messages.discountsHeader} />
                  </td>
                </tr>
                {discounts.map((discount, index) => (
                  <DiscountRow key={index} {...discount} />
                ))}
              </>
            )}
            {showFees && (
              <>
                {fees.map((fee, index) => (
                  <FeeSummary key={index} {...fee} />
                ))}
              </>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>

      <div className={css(styles.totalContainer)}>
        <table className={css(styles.totalContainerTable)}>
          <tfoot>
            <tr data-test-id="payment-summary-footer">
              <td className={css(styles.itemTableHeader)}>
                <FormattedMessage {...messages.total} />
              </td>
              {hasCreditToTrainline && (
                <td
                  aria-label="price"
                  title="price"
                  data-test-id="payment-summary-totalPrice-footer"
                  className={css(styles.ticketsCellAlignRight, styles.totalCreditTrainline)}
                >
                  <FormattedCurrency
                    amount={totalPrice}
                    currencyCode={orderTotal.currencyCode || 'GBP'}
                  />
                </td>
              )}
              <td
                className={css(styles.orderTotal, styles.ticketsCellAlignRight)}
                data-testid="order-total"
              >
                {orderTotal && Object.keys(orderTotal).length ? (
                  <FormattedCurrency
                    amount={orderTotal.amount || 0}
                    currencyCode={orderTotal.currencyCode || 'GBP'}
                  />
                ) : null}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

OrderPayment.propTypes = {
  orderTotal: priceShape,
  orderTotalBeforeDiscountsAndFees: priceShape,
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentDate: PropTypes.string,
  paymentStatus: PropTypes.string,
  paymentErrors: PropTypes.array,
  hasAmendedSeatReservation: PropTypes.bool,
};

export default OrderPayment;
