import { defineMessages } from 'react-intl';

export default defineMessages({
  guest: {
    id: 'StatusInfo.guest',
    defaultMessage: 'Guest account',
  },
  registered: {
    id: 'StatusInfo.registered',
    defaultMessage: 'Registered',
  },
});
