import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, fonts, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  editProfile: {
    padding: 0,
    color: palettes.dp_app_text_base,
    ':hover': {
      color: palettes.dp_app_text_base,
    },
    ':active': {
      color: palettes.dp_app_text_base,
    },
    ':visited': {
      color: palettes.dp_app_text_negative,
    },
  },
  editProfileButton: {
    textAlign: 'left',
    width: 'fill-available',
    fontWeight: fonts.dp_font_weight_regular,
    fontSize: typography.dp_small_font_size_m,
    padding: `${spacers.dp_s8} ${spacers.dp_s24} ${spacers.dp_s8} ${spacers.dp_s16}`,
    border: 'none',
    ':hover': {
      color: 'none',
      background: 'none',
    },
  },
  editProfileButtonActive: {},
  editProfileButtonFullWidth: {
    height: '40px',
  },
  editProfileButtonActiveFullWidth: {},
});
