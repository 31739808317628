import React from 'react';
import { css } from 'aphrodite/no-important';

import { LOCALE_CODES } from '@contactcentre-web/localisation/locales';
import { getCountryFlagLink } from '@contactcentre-web/localisation/localisation';
import FormattedLanguage from '@contactcentre-web/common/FormattedLanguage/FormattedLanguage';

import styles from './styles';

interface Props {
  preferredLanguage: LOCALE_CODES;
  currentLocale: LOCALE_CODES;
}

const LanguageInfo = ({ preferredLanguage, currentLocale }: Props) => (
  <div>
    <FormattedLanguage
      lang={preferredLanguage}
      inLang={currentLocale}
      styleSheet={styles.language}
    />
    <img
      className={css(styles.languageImg)}
      src={getCountryFlagLink(preferredLanguage)}
      alt={preferredLanguage}
    />
  </div>
);

export default LanguageInfo;
