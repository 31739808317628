import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  infoRow: {
    paddingBottom: spacers.dp_s12,
  },
  infoType: {
    fontWeight: 600,
  },
});
