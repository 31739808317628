import { defineMessages } from 'react-intl';

export default defineMessages({
  trainlineProtectInsurance: {
    id: 'Insurance.trainlineProtectInsurance',
    defaultMessage: 'Trainline Protect Insurance ({settlementCountry} {insuranceType})',
  },
  trainlineProtectInsuranceCore: {
    id: 'Insurance.trainlineProtectInsuranceCore',
    defaultMessage: 'Core',
  },
  trainlineProtectInsuranceBusiness: {
    id: 'Insurance.trainlineProtectInsuranceBusiness',
    defaultMessage: 'Business',
  },
  trainlineProtectInsuranceBasic: {
    id: 'Insurance.trainlineProtectInsuranceBasic',
    defaultMessage: 'Basic',
  },
  trainlineProtectInsurancePremium: {
    id: 'Insurance.trainlineProtectInsurancePremium',
    defaultMessage: 'Premium',
  },
  trainlineProtectInsuranceUnknown: {
    id: 'Insurance.trainlineProtectInsuranceUnknown',
    defaultMessage: 'Unknown',
  },
  trainlineProtectInsuranceForBooking: {
    id: 'Insurance.trainlineProtectInsuranceForBooking',
    defaultMessage: '{insuranceMessage} for booking: {bookingIndexes}',
  },
  companjon: {
    id: 'Insurance.companjon',
    defaultMessage: 'Companjon Insurance ({settlementCountry} {insuranceType})',
  },
  tripInsurance: {
    id: 'Insurance.trip',
    defaultMessage: 'Trip Cover',
  },
  essentialInsurance: {
    id: 'Insurance.essential',
    defaultMessage: 'Cancel for any reason',
  },
});
