import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  language: {
    verticalAlign: 'middle',
  },
  languageImg: {
    verticalAlign: 'middle',
    marginLeft: spacers.dp_s8,
    maxWidth: '19px',
  },
});
