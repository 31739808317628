import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom';
import useForceUpdate from 'use-force-update';
import { StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer';
import SameDayVoidContainer from '@contactcentre-web/refunds/SameDayVoid/SameDayVoid';
import { actions as orderHistoryActions } from '@contactcentre-web/order-history/module';
import { useOrderHistory } from '@contactcentre-web/hooks/api/useOrderHistory';
import orderActionKeys, {
  allActions,
  allActionsExcept,
} from '@contactcentre-web/order-actions/OrderActionKeys';
import OrderHistory from '@contactcentre-web/order-history/OrderHistory';
import TermsAndConditionsContainer from '@contactcentre-web/refunds/TermsAndConditions/TermsAndConditions';
import DiscretionaryContainer from '@contactcentre-web/refunds/Discretionary/Discretionary';
import Compensation from '@contactcentre-web/compensation/Compensation';
import ChangeOfJourney from '@contactcentre-web/change-of-journey/ChangeOfJourney';
import UpdateRefundStatus from '@contactcentre-web/update-refund-status/UpdateRefundStatus';

import { actions, selectors } from './module';
import messages from './messages';
import styles from './styles';
import OrderDetails from './components/OrderDetails';

const CONTEXTS = {
  orderDetails: {
    path: '',
    actions: allActionsExcept(orderActionKeys.viewBookings),
    showOrderSummary: true,
    showBanners: true,
  },
  orderHistory: {
    path: '/history',
    actions: allActionsExcept(orderActionKeys.orderHistory),
    showOrderSummary: true,
  },
  refundsSameDayVoid: {
    path: '/sdv',
    actions: allActionsExcept(orderActionKeys.cancelOrder),
  },
  refundsTermsAndConditions: {
    path: '/ts-and-cs-refund',
    actions: allActions(),
  },
  refundsDiscretionary: {
    path: '/discretionary/(requests)?/:quoteId?',
    actions: allActions(),
  },
  compensations: {
    path: '/compensation/:compensationId?',
    actions: allActionsExcept(orderActionKeys.compensation),
  },
  changeOfJourney: {
    path: '/coj',
    actions: allActionsExcept(orderActionKeys.changeOfJourney),
  },
  updateStatus: {
    path: '/update-status/:refundId',
    actions: allActions(),
  },
};

export const CustomerOrder = ({
  order,
  isLoadingOrder,
  error,
  loadOrder,
  loadDelayRepayClaim,
  loadTransactionSummary,
  loadTimetables,
  loadOrderHistory,
  isLoadingTransactionSummary,
}) => {
  const {
    path,
    params: { orderId, customerId },
  } = useRouteMatch();
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const loadOrderRef = React.useRef(loadOrder);
  const loadDelayRepayClaimRef = React.useRef(loadDelayRepayClaim);
  const loadTransactionSummaryRef = React.useRef(loadTransactionSummary);
  const loadTimetablesRef = React.useRef(loadTimetables);
  const loadOrderHistoryRef = React.useRef(loadOrderHistory);
  useOrderHistory(order?.id, order?.orderReference);

  React.useEffect(() => {
    loadOrderRef.current(customerId, orderId);

    return history.listen(() => {
      forceUpdate();
    });
  }, [orderId, customerId, loadOrderRef]);

  React.useEffect(() => {
    loadDelayRepayClaimRef.current(orderId);
  }, [orderId, loadDelayRepayClaimRef]);

  React.useEffect(() => {
    loadTransactionSummaryRef.current(orderId);
  }, [orderId, loadTransactionSummaryRef]);

  React.useEffect(() => {
    loadTimetablesRef.current(orderId);
  }, [orderId, loadTimetablesRef]);

  React.useEffect(() => {
    loadOrderHistoryRef.current(orderId);
  }, [orderId, loadOrderHistoryRef]);

  if (error) {
    return (
      <PageContainer>
        <StatusMessage status="negative">
          <FormattedMessage {...messages.error} values={{ orderId }} />
        </StatusMessage>
      </PageContainer>
    );
  }

  const getRoutePath = (context) => `${path}${context.path}`;

  const isLoadingOrderDetails = isLoadingOrder || isLoadingTransactionSummary;

  if (isLoadingOrderDetails && !order) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={css(styles.pageContainerWrapper)}>
      {!isLoadingOrderDetails && order && (
        <Switch>
          <Route path={getRoutePath(CONTEXTS.orderHistory)} exact>
            <OrderHistory />
          </Route>
          <Route path={getRoutePath(CONTEXTS.refundsSameDayVoid)} exact>
            <SameDayVoidContainer />
          </Route>
          <Route path={getRoutePath(CONTEXTS.refundsTermsAndConditions)} exact>
            <TermsAndConditionsContainer />
          </Route>
          <Route path={getRoutePath(CONTEXTS.refundsDiscretionary)}>
            <DiscretionaryContainer />
          </Route>
          <Route path={getRoutePath(CONTEXTS.compensations)}>
            <Compensation />
          </Route>
          <Route path={getRoutePath(CONTEXTS.changeOfJourney)} exact>
            <ChangeOfJourney />
          </Route>
          <Route path={getRoutePath(CONTEXTS.updateStatus)} exact>
            <UpdateRefundStatus />
          </Route>
          <Route path={getRoutePath(CONTEXTS.orderDetails)}>
            <OrderDetails />
          </Route>
        </Switch>
      )}
    </div>
  );
};

CustomerOrder.propTypes = {
  loadOrder: PropTypes.func.isRequired,
  order: PropTypes.object,
  error: PropTypes.object,
  isLoadingOrder: PropTypes.bool.isRequired,
  loadDelayRepayClaim: PropTypes.func.isRequired,
  loadTransactionSummary: PropTypes.func.isRequired,
  loadTimetables: PropTypes.func.isRequired,
  isLoadingTransactionSummary: PropTypes.bool.isRequired,
  loadOrderHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  order: selectors.getOrder(state),
  error: selectors.selectError(state),
  isLoadingOrder: selectors.isLoadingOrder(state),
  isLoadingTransactionSummary: selectors.isLoadingTransactionSummary(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadOrder: (customerId, orderId) => dispatch(actions.load(customerId, orderId)),
  loadDelayRepayClaim: (orderId) => dispatch(actions.delayRepayClaimLoad(orderId)),
  loadTransactionSummary: (orderId) => dispatch(actions.loadTransactionSummary(orderId)),
  loadTimetables: (orderId) => dispatch(actions.loadTimetablesAttempt(orderId)),
  loadOrderHistory: (orderId) => dispatch(orderHistoryActions.loadAttempt(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrder);
