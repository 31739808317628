import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  loaderContainer: {
    padding: spacers.dp_s48,
  },
  pageContainerWrapper: {
    backgroundColor: '#f6fafc',
  },
  orderHeaderContainer: {
    backgroundColor: palettes.dp_app_backdrop_base,
  },
});
