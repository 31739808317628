import { createSelector } from 'reselect';

import { getLocale } from '@contactcentre-web/authentication/redux/profile/selectors';
import {
  getCurrentManagedGroupNumber,
  canEditCustomerProfile as canEditCustomerProfileSelector,
} from '@contactcentre-web/authentication/redux/selectors';

import { selectors } from '../../module';
import { selectors as customerSelectors } from '../../../customer/module';

export const orderDetailsSelector = createSelector(
  [
    getLocale,
    getCurrentManagedGroupNumber,
    canEditCustomerProfileSelector,
    (state) => customerSelectors.getSelectedCustomer(state.customer),
    selectors.getOrder,
  ],
  (currentLocale, managedGroupNumber, canEditCustomerProfile, selectedCustomer, order) => ({
    currentLocale,
    managedGroupNumber,
    canEditCustomerProfile,
    selectedCustomer,
    order,
  })
);
