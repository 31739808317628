import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { StatusMessage } from '@trainline/depot-web';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import Loader from '@contactcentre-web/common/Loader';
import { actions as orderActions } from '@contactcentre-web/redux-common/actions/order';
import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';
import { getCurrentManagedGroupId } from '@contactcentre-web/authentication/redux/selectors';

import { actions as bookingRefundFormActions } from '../common/module';
import Bookings from '../common/BookingList';

import HelpBanner from './components/HelpBanner';
import SeasonRefundModal from './components/SeasonRefundModal';
import actions from './module';
import selectors from './selectors';
import messages from './messages';
import styles from './styles';

export const TermsAndConditions = ({
  canVoid,
  isLoading,
  hasSucceeded,
  hasFailed,
  bookings,
  refundReasons,
  orderId,
  loadRefunds,
  dispose,
  reload,
  isSeasonTicketDeliveryMethodPostOrToD,
  isSeasonTicketEligibleForManualRefund,
  hasRefundPermissions,
  managedGroupId,
}) => {
  const { customerId, orderId: orderReference } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const loadRefundsRef = React.useRef(loadRefunds);

  const noPermissionsAlert = hasRefundPermissions ? undefined : (
    <div>
      <StatusMessage status="warning">
        <FormattedMessage {...messages.noPermissions} />
      </StatusMessage>
    </div>
  );

  React.useEffect(() => {
    loadRefundsRef.current(customerId, orderReference);
  }, [customerId, orderId, loadRefundsRef]);

  React.useEffect(
    () =>
      history.listen((state) => {
        if (pathname !== state.pathname) {
          dispose();
        }
      }),
    [pathname]
  );

  if (hasFailed) {
    return null;
  }

  if (isLoading || (!hasFailed && !hasSucceeded)) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  if (isSeasonTicketDeliveryMethodPostOrToD) {
    return <SeasonRefundModal />;
  }

  return (
    <PageContainer>
      <HelpBanner />
      {isSeasonTicketEligibleForManualRefund && (
        <div className={css(styles.alert)}>
          <StatusMessage status="info">
            <FormattedMessage
              {...messages.notEligibleForAutomatedRefund}
              testId="eligibleForManualRefund"
            />
          </StatusMessage>
        </div>
      )}
      <Bookings
        bookings={bookings}
        customerId={customerId}
        orderHistoryRoute={`/customers/${customerId}/bookings/${orderReference}/history`}
        orderReference={orderReference}
        refundReasons={refundReasons}
        noPermissionsAlert={noPermissionsAlert}
        orderId={orderId}
        refundReasonDisabled
        confirmPrompt={
          !canVoid
            ? null
            : {
                header: <FormattedMessage {...messages.confirmSdvEligibleRefundHeader} />,
                body: <FormattedMessage {...messages.confirmSdvEligibleRefundBody} />,
              }
        }
        reload={() => reload(customerId, orderReference)}
        refundType="termsAndConditions"
        managedGroupId={managedGroupId}
      />
    </PageContainer>
  );
};

TermsAndConditions.propTypes = {
  loadRefunds: PropTypes.func.isRequired,
  dispose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasSucceeded: PropTypes.bool,
  hasFailed: PropTypes.bool,
  canVoid: PropTypes.bool,
  bookings: PropTypes.array,
  refundReasons: PropTypes.array,
  orderId: PropTypes.string,
  reload: PropTypes.func.isRequired,
  isSeasonTicketDeliveryMethodPostOrToD: PropTypes.bool.isRequired,
  isSeasonTicketEligibleForManualRefund: PropTypes.bool.isRequired,
  hasRefundPermissions: PropTypes.bool,
  managedGroupId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.inProgress(state) || orderSelectors.isLoadingOrder(state),
  hasSucceeded: selectors.hasSucceeded(state) && orderSelectors.isLoaded(state),
  hasFailed: selectors.hasFailed(state) || orderSelectors.selectError(state),
  canVoid: selectors.canVoid(state),
  bookings: selectors.getBookings(state),
  refundReasons: selectors.refundReasons(state),
  orderId: selectors.getOrderId(state),
  isSeasonTicketDeliveryMethodPostOrToD: selectors.isSeasonTicketDeliveryMethodPostOrToD(state),
  isSeasonTicketEligibleForManualRefund: selectors.isSeasonTicketEligibleForManualRefund(state),
  hasRefundPermissions: selectors.hasRefundPermissions(state),
  managedGroupId: getCurrentManagedGroupId(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRefunds: (_, orderReference) => {
    dispatch(actions.loadRefunds(orderReference));
  },
  dispose: () => {
    dispatch(actions.dispose());
    dispatch(bookingRefundFormActions.resetForm());
  },
  reload: (customerId, orderReference) => {
    dispatch(actions.dispose());
    dispatch(bookingRefundFormActions.resetForm());

    dispatch(orderActions.load(customerId, orderReference));
    dispatch(actions.loadRefunds(orderReference));
  },
});

const TermsAndConditionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditions);
TermsAndConditionsContainer.displayName = 'TermsAndConditionsRefund';
export default TermsAndConditionsContainer;
