import React from 'react';
import { css } from 'aphrodite/no-important';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageContainer from '@contactcentre-web/common/PageContainer';

import Journey from '../../components/Journey';
import CustomerInfo from '../../components/CustomerInfo';
import OrderInfo from '../../components/OrderInfo';
import OrderActions from '../../components/OrderActions';
import TransactionSummary from '../TransactionSummary';

import styles from './styles';
import { orderDetailsSelector } from './selectors';

const OrderDetails = () => {
  const { currentLocale, managedGroupNumber, canEditCustomerProfile, selectedCustomer, order } =
    useSelector(orderDetailsSelector);
  const {
    params: { customerId },
  } = useRouteMatch<{ customerId: string }>();

  const isCustomerBookingsPage = useRouteMatch({
    path: '/customers/:customerId/bookings',
    exact: true,
  });

  return (
    <>
      <div className={css(styles.pageHeaderWrapper)}>
        <CustomerInfo
          customerId={customerId}
          canNavigateToBookings={!isCustomerBookingsPage}
          expandHeader={() => null}
          currentLocale={currentLocale}
          managedGroupNumber={managedGroupNumber}
          canEditCustomerProfile={canEditCustomerProfile}
          fullWidth={false}
          {...selectedCustomer}
        />
        <OrderInfo />
      </div>

      <PageContainer>
        <OrderActions />

        <Journey />
      </PageContainer>

      {order && <TransactionSummary />}
    </>
  );
};

export default OrderDetails;
