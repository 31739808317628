import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const insuranceMessage = (insuranceType, settlementCountry, vendor) => {
  let insuranceTypeMsg = null;

  switch (insuranceType) {
    case 'Core':
      insuranceTypeMsg = messages.trainlineProtectInsuranceCore;
      break;
    case 'Business':
      insuranceTypeMsg = messages.trainlineProtectInsuranceBusiness;
      break;
    case 'Basic':
      insuranceTypeMsg = messages.trainlineProtectInsuranceBasic;
      break;
    case 'Premium':
      insuranceTypeMsg = messages.trainlineProtectInsurancePremium;
      break;
    case 'Essential':
      insuranceTypeMsg = messages.essentialInsurance;
      break;
    case 'Trip':
      insuranceTypeMsg = messages.tripInsurance;
      break;
    default:
      insuranceTypeMsg = messages.trainlineProtectInsuranceUnknown;
  }

  return {
    ...(vendor === 'COMPANJON' ? messages.companjon : messages.trainlineProtectInsurance),
    values: {
      insuranceType: <FormattedMessage {...insuranceTypeMsg} />,
      settlementCountry,
    },
  };
};

export const insuranceForBookingMessage = (insuranceType, settlementCountry, bookingIndexes) => ({
  ...messages.trainlineProtectInsuranceForBooking,
  values: {
    insuranceMessage: <FormattedMessage {...insuranceMessage(insuranceType, settlementCountry)} />,
    bookingIndexes: bookingIndexes.map((i) => i + 1).join(' & '),
  },
});
