import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'customerInfo.email',
    defaultMessage: 'Email',
  },
  address: {
    id: 'customerInfo.address',
    defaultMessage: 'Address',
  },
  migrationDate: {
    id: 'customerInfo.migrationDate',
    defaultMessage: 'Migration Date',
  },
  corporateName: {
    id: 'customerInfo.corporateName',
    defaultMessage: 'Corporate Name',
  },
  clientReference: {
    id: 'customerInfo.clientReference',
    defaultMessage: 'Client Reference',
  },
  corporateReference: {
    id: 'customerInfo.corporateReference',
    defaultMessage: 'Corporate Reference',
  },
  editProfile: {
    id: 'customerInfo.editProfile',
    defaultMessage: 'Edit Profile',
  },
  deleteAccount: {
    id: 'customerInfo.deleteAccount',
    defaultMessage: 'Delete Account',
  },
  customerProfileUpdated: {
    id: 'customerInfo.customerProfileUpdated',
    defaultMessage: "The customer's profile has been successfully updated.",
  },
});
