import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import InsuranceIcon from '@contactcentre-web/common/InsuranceIcon/InsuranceIcon';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';

import RailcardLabel from '../RailcardLabel';

import styles from './styles';
import messages from './messages';

const ProductDetails = ({
  details: { origin, destination, isReturnFare, name, localAreaValidity },
  type,
  isFlexiSeason,
  discount,
}) => {
  switch (type) {
    case ProductType.TravelProduct:
      return (
        <td className={css(styles.itemCell)}>
          <h4>{`${origin} - ${destination}`}</h4>
          {isReturnFare && (
            <div>
              <FormattedMessage {...messages.return} />
            </div>
          )}
        </td>
      );
    case ProductType.Season:
      return (
        <td className={css(styles.itemCell)}>
          <h4>{`${origin} - ${destination}`}</h4>
          <div>
            <FormattedMessage {...messages.season} values={{ isFlexiSeason }} />
            {discount && <RailcardLabel name={discount.name} />}
          </div>
        </td>
      );
    case ProductType.Insurance:
      return (
        <td aria-label="Insurance" title="Insurance" className={css(styles.itemCell)}>
          <h4>
            <InsuranceIcon />
          </h4>
        </td>
      );
    case ProductType.Railcard:
      return (
        <td className={css(styles.itemCell)}>
          <h4>{name}</h4>
        </td>
      );
    case ProductType.Travelcard:
      return (
        <td className={css(styles.itemCell)}>
          <h4>{localAreaValidity}</h4>
          <div>
            <FormattedMessage {...messages.travelcard} />
          </div>
        </td>
      );
    default:
      return null;
  }
};

ProductDetails.propTypes = {
  details: PropTypes.shape({
    destination: PropTypes.string,
    isReturnFare: PropTypes.bool,
    origin: PropTypes.string,
    name: PropTypes.string,
    localAreaValidity: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
  isFlexiSeason: PropTypes.bool,
  discount: PropTypes.shape({ name: PropTypes.string }),
};

export default ProductDetails;
