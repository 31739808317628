import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  // workaround for fixing <Message /> styling clashing with <Tag /> styling
  tag: {
    ':nth-child(1n) > div': {
      padding: spacers.dp_s4,
    },
  },
});
