import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  spacers,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    paddingBottom: spacers.dp_s32,
    margin: '0 auto',
    width: '1036px',
  },
  rowHeader: {
    marginBottom: spacers.dp_s32,
    alignItems: 'center',
  },
  label: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
  },
  orderReference: {
    fontSize: typography.dp_hero_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    letterSpacing: '-0.2px', // TODO: replace with depot-web typography when updated in depot-web
    lineHeight: 1,
    color: colors.info.dark,
    borderBottom: `1px dashed ${colors.info.dark}`,
    textDecoration: 'none',
    marginRight: spacers.dp_s16,
  },
  orderReferenceSelected: {
    border: 'none',
    color: 'inherit',
    pointerEvent: 'none',
  },
  price: {
    fontSize: typography.dp_hero_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_hero_line_height,
    letterSpacing: '-0.2px', // TODO: replace with depot-web typography when updated in depot-web
  },
  alignRight: {
    textAlign: 'right',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notification: {
    marginBottom: spacers.dp_s32,
  },
  customerAuthentication: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    display: 'inline-block',
    backgroundColor: colors.info.light,
    color: colors.info.dark,
    fontWeight: fonts.dp_font_weight_bold,
    margin: `${spacers.dp_s4} 0 0 -${spacers.dp_s8}`,
    padding: `${spacers.dp_s4} ${spacers.dp_s8}`,
    borderRadius: borderRadius.dp_r8,
  },
  alert: {
    marginBottom: spacers.dp_s32,
  },
  bookedViaContainer: {
    maxWidth: '20%',
  },
  orderReferenceContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
