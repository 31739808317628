import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import styles from './styles';

interface Message {
  id: string;
  defaultMessage: string;
}

interface Props {
  type: Message;
  value: string | undefined;
}

const InfoRow = ({ type, value }: Props) =>
  value ? (
    <div className={css(styles.infoRow)}>
      <span className={css(styles.infoType)}>
        <FormattedMessage {...type} />
        {': '}
      </span>
      <span>{value}</span>
    </div>
  ) : null;

export default InfoRow;
