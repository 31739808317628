import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    borderRadius: borderRadius.dp_r12,
    border: `1px solid ${palettes.dp_app_border_base}`,
    display: 'grid',
    gridTemplateAreas: `"header" "details" "actions"`,
    flex: '1 0 45%',
    padding: spacers.dp_s24,
  },
  fullWidth: {
    gridTemplateAreas: `"header header header" "details details actions"`,
  },
  header: {
    gridArea: 'header',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacers.dp_s8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  details: {
    gridArea: 'details',
    paddingTop: spacers.dp_s12,
  },
  actions: {
    gridArea: 'actions',
    display: 'flex',
    flexDirection: 'row',
    gap: spacers.dp_s8,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  notificationBar: {
    ':nth-child(1n) > div': {
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});
